/** @format */

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getVoterIdeas } from "../../actions/sessions.js";
import { checkUserAuth } from "../../actions/auth.js";

import { useNavigate } from "react-router-dom";
//import io from "socket.io-client";
import VotingIntro from "./VotingIntro.js";
import socket from "../../socket.js"; // Import the shared socket instance
import * as routes from "../../constants/routeNames.js";

const serverUrl = `${process.env.REACT_APP_SERVER_BASE_URL}`;

// const socket = io(serverUrl);

const getColorClasses = (colorScheme) => {
  switch (colorScheme) {
    case "purple":
      return {
        bg: "bg-purple100",
        button: "bg-purple700",
        hover: "hover:bg-purple700",
        focus: "focus:ring-purple300",
        text: "text-purple700",
      };
    case "blue":
      return {
        bg: "bg-blue100",
        button: "bg-blue700",
        hover: "hover:bg-blue700",
        focus: "focus:ring-blue300",
        text: "text-blue700",
      };
    default: // green
      return {
        bg: "bg-green100",
        button: "bg-green700",
        hover: "hover:bg-green700",
        focus: "focus:ring-green300",
        text: "text-green700",
      };
  }
};

const VotingCard = ({ idea, onVote, colorScheme = "green", selectedValue }) => {
  const colors = getColorClasses(colorScheme);
  const handleVote = (value) => {
    onVote(value);
  };

  return (
    <div
      className={`${colors.bg} rounded-lg w-full h-full flex flex-grow flex-col justify-between`}
    >
      <div className="p-4 flex-grow overflow-y-auto">
        <p className="text-orange900">{idea}</p>
      </div>
      <div className="relative">
        <div
          className={`${colors.bg} absolute opacity-50 inset-x-0 w-full h-[1px] drop-shadow-customup`}
        ></div>
        <div className="flex justify-between relative z-10 p-4">
          {[1, 2, 3, 4, 5, 6, 7].map((value) => (
            <button
              key={value}
              onClick={() => handleVote(value)}
              className={`w-8 h-8 rounded-full border border-graybrown400 flex items-center justify-center
                            ${
                              value <= selectedValue
                                ? `${colors.button} text-white`
                                : "bg-white text-graybrown400 hover:text-white"
                            }
                            ${colors.hover} focus:outline-none focus:ring-2 ${
                colors.focus
              }`}
            >
              {value}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

const PlaceholderCard = ({ ideaNumber, colorScheme = "green" }) => {
  const colors = getColorClasses(colorScheme);

  return (
    <div
      className={`${colors.bg} rounded-lg p-4 w-full h-full min-h-[120px] flex flex-grow items-center justify-center`}
    >
      <p className={`${colors.text} text-2xl font-normal`}>IDEA {ideaNumber}</p>
    </div>
  );
};

const VotingRandomSampling = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");

  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));
  const [votedCards, setVotedCards] = useState([]);

  const [voterIdeas, setVoterIdeas] = useState([]);
  const [ideas, setIdeas] = useState([""]);
  const [submissionCount, setSubmissionCount] = useState(0);
  const [totalParticipants, setTotalParticipants] = useState(0);

  const [submitted, setSubmitted] = useState(false);

  const [roomCode, setRoomCode] = useState(
    JSON.parse(localStorage.getItem("room"))?.roomCode
  );
  const checkAuthStatus = async () => {
    console.log("Checking user auth");
    const authenticated = await dispatch(checkUserAuth(user, navigate));
    if (!authenticated) {
      console.log("Not authenticated");
      navigate(routes.login);
    }
  };

  useEffect(() => {
    if (!user) {
      console.log(!user);
      navigate(routes.login);
    } else {
      if (user) {
        checkAuthStatus();
      }
    }
  }, []);

  useEffect(() => {
    // Fetch initial ideas when component mounts
    if (!user) {
      console.log("Here 1");
      navigate(routes.beginHere);
    }
    if (!roomCode) {
      console.log("Here 2");

      navigate(routes.beginHere);
    }
    setUserId(user?.result._id);
    socket.on("userSubmittedVotes", (data) => {
      if (data.socketRoomCode == roomCode) {
        setSubmissionCount(data.submissionCount);
      }
    });
    socket.on("voteComplete", ({ socketRoomCode }) => {
      socketRoomCode == roomCode && navigate(routes.leaderboard);
    });

    // socket.onAny((eventName, ...args) => {
    //   console.log(`Received event: ${eventName}`, args);
    // });

    return () => {
      socket.off("userSubmittedVotes");
      socket.off("voteComplete");
    };
  }, [navigate, roomCode, user, userId]);

  //   useEffect(() => {
  //     socket.on("connect", () => {
  //       console.log("Connected to socket server?");
  //     });
  //   }, []);

  useEffect(() => {
    if (!user) {
      navigate(routes.login);
    }
    setUserId(user?.result._id);
  }, [user]);
  const getVoterInfo = async () => {
    const data = await dispatch(getVoterIdeas(user, navigate));
    if (data) {
      setVoterIdeas(data.voterIdeas);
      setTotalParticipants(data.totalParticipants);
      if (data.userSubmitted) {
        setSubmitted(true);
      }
      setSubmissionCount(data.submissionCount);
    }
    console.log("Voter data received: ", voterIdeas);
  };

  useEffect(() => {
    console.log("voterIdeas: ", voterIdeas);
    if (voterIdeas && voterIdeas != {}) {
      const ideaList = voterIdeas.map((i) => {
        return i.ideaText;
      });
      setIdeas(ideaList);
    } else {
    }
    console.log(ideas);
  }, [voterIdeas]);

  useEffect(() => {
    if (!user) {
      navigate(routes.login);
    }
    getVoterInfo();
  }, []);
  useEffect(() => {
    console.log("voted cards:", votedCards);
    const allVoted = votedCards.length == ideas.length;
    setAllVoted(allVoted);
  }, [votedCards]);

  const [currentPage, setCurrentPage] = useState(0);
  const ideasPerPage = 6;
  const [allVoted, setAllVoted] = useState(false);

  const handleVote = (value, cardIndex) => {
    const globalIndex = currentPage * ideasPerPage + cardIndex;
    setVotedCards((prev) => {
      const newVotedCards = [...prev];
      newVotedCards[globalIndex] = value;
      return newVotedCards;
    });
  };

  const colorSchemes = ["green", "purple", "blue"];

  const pageCount = Math.ceil(ideas.length / ideasPerPage);
  const currentIdeas = ideas.slice(
    currentPage * ideasPerPage,
    (currentPage + 1) * ideasPerPage
  );

  const goToPreviousPage = () => {
    setCurrentPage((prev) => Math.max(0, prev - 1));
  };

  const goToNextPage = () => {
    setCurrentPage((prev) => Math.min(pageCount - 1, prev + 1));
  };

  const canVoteOnCard = (index) => {
    const globalIndex = currentPage * ideasPerPage + index;
    if (globalIndex === 0) return true;
    return votedCards[globalIndex - 1] !== undefined;
  };

  const handleSubmit = () => {
    // Handle the submission of votes
    const contributionIds = voterIdeas.map((i) => {
      return i._id;
    });
    socket.emit("submitVotes", {
      roomCode,
      userId,
      contributionIds,
      votes: votedCards,
    });

    console.log("Votes submitted: ", votedCards);
    setSubmitted(true);
  };

  return submitted ? (
    <VotingIntro
      preVote={false}
      totalParticipants={totalParticipants}
      submittedParticipants={submissionCount}
    />
  ) : (
    <div>
      {/* Session question mobile */}
      <div className="md:hidden mt-14 mx-5 flex flex-col justify-center items-center">
        <div className="flex-col items-center mx-auto w-full py-6 mb-4 bg-orange100 rounded-md drop-shadow-custom">
          <h1 className="font-semibold w-full text-base text-center">
            Session Question:
          </h1>
          <p className="text-sm mt-2 text-center mx-5">
            How can we integrate AI tools into our weekly design sprints to
            increase productivity?
          </p>
        </div>
      </div>

      {/* Round and timer mobile */}
      <div className="md:hidden flex justify-between mx-5 mt-6 mb-2 text-base text-orange900">
        <p className="text-base md:text-xl font-normal">Voting</p>
        <p className="text-base md:text-xl font-normal">Random sampling</p>
      </div>

      {/* Round and timer medium screens */}
      <div className="mx-10 text-base text-orange900">
        <div className="hidden md:flex justify-between mx-auto mt-20 mb-4 text-base text-orange900 font-semibold max-w-[800px]">
          <p className="text-base md:text-xl font-normal">Voting</p>
          <p className="text-base md:text-xl font-normal">Random Sampling</p>
        </div>
      </div>

      {/* Session question medium screens */}
      <div className="mx-10">
        <div className="hidden md:flex mt-4 mx-auto flex-col justify-center items-center max-w-[800px]">
          <div className="flex-col items-center mx-auto w-full py-6 mb-10 bg-orange100 rounded-md drop-shadow-custom">
            <h1 className="font-semibold w-full text-base text-center">
              Session Question:
            </h1>
            <p className="text-sm mt-2 text-center mx-5">
              How can we integrate AI tools into our weekly design sprints to
              increase productivity?
            </p>
          </div>
        </div>
      </div>

      {/* VotingCard components */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mx-auto w-full h-full lg:max-w-[80vw] px-5 md:px-10 drop-shadow-custom">
        {currentIdeas.map((idea, index) => {
          const globalIndex = currentPage * ideasPerPage + index;
          return (
            <div
              key={globalIndex}
              className="w-full h-full md:max-w-[320px] max-h-[200px] mx-auto"
            >
              {canVoteOnCard(index) ? (
                <VotingCard
                  idea={idea}
                  onVote={(value) => handleVote(value, index)}
                  colorScheme={colorSchemes[globalIndex % 3]}
                  selectedValue={votedCards[globalIndex]}
                />
              ) : (
                <PlaceholderCard
                  ideaNumber={globalIndex + 1}
                  colorScheme={colorSchemes[globalIndex % 3]}
                />
              )}
            </div>
          );
        })}
      </div>

      {/* Navigation buttons */}
      {ideas.length > ideasPerPage && (
        <div className="flex justify-between mt-4 mx-5 mb-24 md:w-[320px] md:mx-auto">
          <button
            onClick={goToPreviousPage}
            disabled={currentPage === 0}
            className={`p-1 rounded-full focus:outline-none focus:ring-2 focus:ring-orange200 ${
              currentPage === 0
                ? "opacity-50 cursor-not-allowed"
                : "hover:bg-orange100"
            }`}
          >
            <img
              src="https://brainwrite-assets.s3.amazonaws.com/leftarrowrandomsampling.svg"
              alt="Previous"
              className="w-8 h-8"
            />
          </button>
          {/* progress dots */}
          <div className="flex space-x-4 items-center">
            {[...Array(pageCount)].map((_, index) => (
              <div
                key={index}
                className={`w-4 h-4 rounded-full border border-graybrown200 ${
                  index === currentPage ? "bg-graybrown200" : "bg-transparent"
                }`}
              ></div>
            ))}
          </div>
          <button
            onClick={goToNextPage}
            disabled={currentPage === pageCount - 1}
            className={`p-1 rounded-full focus:outline-none focus:ring-2 focus:ring-orange200 ${
              currentPage === pageCount - 1
                ? "opacity-50 cursor-not-allowed"
                : "hover:bg-orange100"
            }`}
          >
            <img
              src="https://brainwrite-assets.s3.amazonaws.com/rightarrowrandomsampling.svg"
              alt="Next"
              className="w-8 h-8"
            />
          </button>
        </div>
      )}

      {/* Submit button */}
      {allVoted && (
        <div className="flex justify-center mt-6">
          <button
            onClick={handleSubmit}
            className="bg-orange500 hover:bg-orange400 text-white mt-10 rounded-2xl text-base text-justify-center text-items-center  font-medium px-12 py-3 mb-20 hover:cursor-pointer"
          >
            Submit Votes
          </button>
        </div>
      )}
    </div>
  );
};

export default VotingRandomSampling;
